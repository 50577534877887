import React from "react";
import { useTranslation } from "react-i18next";

const GrandPrixPosition = (props: any) => {
  const driver = props.driver;
  const session = props.session;
  const championship = props.championship;
  const total = props.total;
  const championshipName = props?.championshipName;

  let limitQ3 = championship >= 15 ? 10 : Math.ceil(total / 2);
  if (championshipName != null && championshipName.includes("IndyCar"))
    limitQ3 = 6;
  useTranslation();

  const getClass = () => {
    if (session === "Q2" && driver.pos > limitQ3) return "q2-eliminated";
    else if (
      driver.pos > 1 &&
      ["DNF", "DSQ", "DQ", ""].includes(driver.gapGral)
    )
      return "retired";
  };
  return (
    <div className={`gp-pos ${getClass()} flex-center`}>
      <label>{driver.pos2 != null ? driver.pos2 : driver.pos}</label>
    </div>
  );
};

export default GrandPrixPosition;
